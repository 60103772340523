import { Component, ElementRef } from '@angular/core';

import { ComponentsService } from '../../services/components.service';

import {} from '@angular/material/checkbox';

@Component({
  selector: 'template-component-social-media-posts',
  templateUrl: './social-media-posts.component.html',
  styleUrls: ['./social-media-posts.component.scss'],
})
export class SocialMediaPostsComponent {
  public componentId: string;

  constructor(
    private elementRef: ElementRef,
    private componentsFactory: ComponentsService
  ) {
    this.componentsFactory.registerDirective({
      type: 'rise-social-media-posts',
      element: this.elementRef.nativeElement,
      show: () => {
        this.componentId = componentsFactory.selected.id;
        this.load();
      },
    });
  }

  load() {
    //TODO: Implement this
  }
}
